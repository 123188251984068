.container {
  margin: 20px;
  background-image: linear-gradient(
    85deg,
    #030303 -20%,
    #181617 50%,
    #262324 120%
  );
  border: solid #2f2f2f 1px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  padding: 20px;
  min-width: 250px;
}
.icon-weather {
  width: 150px;
}
.header {
  display: flex;
  justify-content: space-between;
}

.icon {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.icon--svg {
  font-size: 30px;
  height: 8rem;
}

.status {
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.detail--container {
  margin-top: 2rem;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'wind degrees'
    'humidity degrees'
    'sunrise degrees';

  .detail--icon {
    width: 40px;
  }

  .detail--wind {
    grid-area: wind;
    display: flex;
    align-items: center;
  }

  .detail--humidity {
    grid-area: humidity;
    display: flex;
    align-items: center;
  }

  .detail--sunrise {
    grid-area: sunrise;
    display: flex;
    align-items: center;

    .icon {
      margin-top: -6px;
    }
  }

  .detail--degrees {
    grid-area: degrees;
    align-self: center;
    justify-self: end;
    font-size: 5rem;

    &__skeleton {
      @extend .detail--degrees;
      margin-top: -5px;
    }
  }
}
